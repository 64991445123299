import * as React from "react"
import { addPropertyControls, ControlType } from "framer"

export function FormSpark(props) {
    const generateInitialFormData = () => {
        let data = {
            url: typeof window !== "undefined" ? window.location.href : "",
        }
        props.textfields.forEach((field) => {
            data[field.name] = ""
        })
        props.selects.forEach((select) => {
            data[select.name] = "none"
        })
        props.textareas.forEach((area) => {
            data[area.name] = ""
        })
        return data
    }

    const [formData, setFormData] = React.useState(generateInitialFormData())
    const [submitting, setSubmitting] = React.useState(false)

    const onSubmit = (e) => {
        e.preventDefault()
        setSubmitting(true)

        const formElement = document.createElement("form")
        formElement.action = `https://submit-form.com/${props.formID}`
        formElement.method = "POST"

        let senderName = ""

        for (const key in formData) {
            const input = document.createElement("input")
            input.type = "hidden"
            input.name = key
            input.value = formData[key]
            formElement.appendChild(input)

            // Check if this field is marked as sender name
            if (
                props.textfields.find(
                    (field) => field.name === key && field.useAsSenderName
                )
            ) {
                senderName = formData[key]
            }
        }

        // Manually append the value from the Uploadcare widget
        if (props.uploadcareID) {
            const uploadcareInput = document.getElementById("fileupload")
            if (uploadcareInput) {
                const uploadcareField = document.createElement("input")
                uploadcareField.type = "hidden"
                uploadcareField.name = "fileupload"
                uploadcareField.value = uploadcareInput.value // Get the value directly from the widget
                formElement.appendChild(uploadcareField)
            }
        }

        // Add sender's name field if a sender name is set
        if (senderName) {
            const senderInput = document.createElement("input")
            senderInput.type = "hidden"
            senderInput.name = "_email.from"
            senderInput.value = senderName
            formElement.appendChild(senderInput)
        }

        document.body.appendChild(formElement)
        formElement.submit()

        setFormData(generateInitialFormData())
        setSubmitting(false)
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((prevData) => ({ ...prevData, [name]: value }))
    }

    React.useEffect(() => {
        if (props.uploadcareID && !window.uploadcare) {
            const scriptId = "uploadcare-script"

            // Add custom CSS for Uploadcare button
            const style = document.createElement("style")
            style.textContent = `
                .uploadcare--widget__button.uploadcare--widget__button_type_open {
                    width: 100%;
                    background-color: transparent;
                    outline: none;
                    cursor: pointer;
                    color: ${props.buttonColor};
                }
            `

            document.head.appendChild(style)

            // Load Uploadcare script
            if (!document.getElementById(scriptId)) {
                const script = document.createElement("script")
                script.id = scriptId
                script.src =
                    "https://ucarecdn.com/libs/widget/3.x/uploadcare.full.min.js"
                script.async = true
                document.head.appendChild(script)
            }
        }
    }, [props.uploadcareID, props.buttonColor])

    const formStyle = {
        ...styles.form,
        gap: props.formGap,
        fontSize: props.fontSize + "px",
        fontFamily: props.fontFamily,
    }

    const elementStyle = {
        borderRadius: props.elementBorderRadius,
        height: props.elementHeight,
        fontSize: props.fontSize + "px",
        fontFamily: props.fontFamily,
        backgroundColor: props.elementBackgroundColor,

        boxShadow: `0px 0px 0px 2px ${props.elementShadowColor}`,
    }

    const textareaStyle = {
        ...styles.textarea,
        borderRadius: props.elementBorderRadius,
        height: props.textareaHeight,
        fontSize: props.fontSize + "px",
        fontFamily: props.fontFamily,
        backgroundColor: props.elementBackgroundColor,
        boxShadow: `0px 0px 0px 2px ${props.elementShadowColor}`,
    }

    const buttonStyle = {
        ...styles.button,
        backgroundColor: props.buttonColor,
        borderRadius: props.buttonBorderRadius,
        height: props.buttonHeight,
        fontSize: props.fontSize + "px",
        fontFamily: props.fontFamily,
        boxShadow: `0px 0px 0px 2px ${props.elementShadowColor}`,
    }

    return (
        <form
            onSubmit={onSubmit}
            style={{
                ...props.style,
                ...formStyle,
            }}
            id={props.htmlID} // Adding HTML ID to the form element
        >
            <input
                style={styles.input}
                type="hidden"
                name="Url"
                value={formData.url}
                readOnly // Make the URL field read-only so users can't edit it
            />{" "}
            {props.textfields.map((field, index) => (
                <input
                    key={index}
                    style={{ ...styles.input, ...elementStyle }}
                    type={field.type}
                    name={field.type === "email" ? "email" : field.name}
                    placeholder={field.name}
                    required={field.required}
                    onChange={handleChange}
                />
            ))}
            {props.selects.map((select, index) => (
                <select
                    key={index}
                    style={{ ...styles.select, ...elementStyle }}
                    name={select.name}
                    required={select.required}
                    onChange={handleChange}
                >
                    <option value="" disabled selected>
                        {select.name}
                    </option>
                    {select.options.map((option, optionIndex) => (
                        <option key={optionIndex} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            ))}
            {props.textareas.map((area, index) => (
                <textarea
                    key={index}
                    style={textareaStyle}
                    name={area.name}
                    placeholder={area.name}
                    required={area.required}
                    onChange={handleChange}
                ></textarea>
            ))}
            {props.uploadcareID && (
                <input
                    type="hidden"
                    id="fileupload"
                    name="fileupload"
                    role="uploadcare-uploader"
                    data-public-key={props.uploadcareID}
                />
            )}
            <button style={buttonStyle} type="submit" disabled={submitting}>
                {props.buttonText}
            </button>
        </form>
    )
}

addPropertyControls(FormSpark, {
    formID: {
        type: ControlType.String,
        title: "Form ID",
        defaultValue: "",
    },
    htmlID: {
        // New property for HTML ID
        type: ControlType.String,
        title: "HTML ID",
        defaultValue: "",
    },
    fontFamily: {
        type: ControlType.String,
        title: "Font Family",
        defaultValue: "Inter, sans-serif",
    },
    textfields: {
        type: ControlType.Array,
        propertyControl: {
            type: ControlType.Object,
            controls: {
                name: {
                    type: ControlType.String,
                    placeholder: "Field Name",
                    title: "Name",
                },
                type: {
                    type: ControlType.Enum,
                    options: ["text", "number", "date", "email"],
                    optionTitles: ["Text", "Number", "Date", "email"],
                    title: "Type",
                },
                required: {
                    type: ControlType.Boolean,
                    title: "Required",
                    defaultValue: true,
                },
                useAsSenderName: {
                    type: ControlType.Boolean,
                    title: "Use as sender name",
                    defaultValue: false,
                },
            },
        },
        title: "Textfields",
        defaultValue: [
            {
                name: "name",
                type: "text",
                required: true,
                useAsSenderName: false,
            },
            { name: "age", type: "number", required: true },
            { name: "birthdate", type: "date", required: true },
        ],
    },

    selects: {
        type: ControlType.Array,
        propertyControl: {
            type: ControlType.Object,
            controls: {
                name: {
                    type: ControlType.String,
                    placeholder: "Service",
                    title: "Name",
                },
                options: {
                    type: ControlType.Array,
                    propertyControl: {
                        type: ControlType.String,
                        placeholder: "SEO",
                    },
                    title: "Options",
                },
                required: {
                    type: ControlType.Boolean,
                    title: "Required",
                    defaultValue: true,
                },
            },
        },
        title: "Selects",
        defaultValue: [
            {
                name: "tjanst",
                options: ["hemstad", "foretagsstad", "fastighetsservice"],
                required: true,
            },
        ],
    },

    textareas: {
        type: ControlType.Array,
        propertyControl: {
            type: ControlType.Object,
            controls: {
                name: {
                    type: ControlType.String,
                    placeholder: "Meddelande",
                    title: "Name",
                },
                required: {
                    type: ControlType.Boolean,
                    title: "Required",
                    defaultValue: true,
                },
            },
        },
        title: "Textareas",
        defaultValue: [{ name: "meddelande", required: true }],
    },
    elementBorderRadius: {
        type: ControlType.Number,
        title: "Element Border Radius",
        defaultValue: 15,
    },
    buttonBorderRadius: {
        type: ControlType.Number,
        title: "Button Border Radius",
        defaultValue: 8,
    },

    buttonColor: {
        type: ControlType.Color,
        title: "Button Color",
        defaultValue: "#f3b758",
    },
    elementBackgroundColor: {
        type: ControlType.Color,
        title: "Element Background Color",
        defaultValue: "#F7F7F7",
    },
    elementShadowColor: {
        type: ControlType.Color,
        title: "Element Shadow Color",
        defaultValue: "rgba(255, 255, 255, 0.1)", // Default border color
    },
    formGap: {
        type: ControlType.Number,
        title: "Form Gap",
        defaultValue: 10,
    },
    buttonHeight: {
        type: ControlType.Number,
        title: "Button Height",
        defaultValue: 50,
    },
    buttonText: {
        type: ControlType.String,
        title: "Button Text",
        defaultValue: "SKICKA",
    },
    elementHeight: {
        type: ControlType.Number,
        title: "Element Height",
        defaultValue: 54,
    },
    textareaHeight: {
        type: ControlType.Number,
        title: "Textarea Height",
        defaultValue: 100,
    },
    fontSize: {
        type: ControlType.Number,
        title: "Font Size",
        defaultValue: 16,
    },
    uploadcareID: {
        type: ControlType.String,
        title: "Uploadcare ID",
        defaultValue: "",
    },
})

const styles = {
    form: {
        display: "flex",
        flexDirection: "column",
        padding: "0px",
        boxShadow: "none",
        backgroundColor: "transparent",
    },
    input: {
        padding: "15px",
        width: "100%",
        border: "none",
        backgroundColor: "#F7F7F7",
        outline: "none",
    },
    select: {
        overflow: "visible",
        padding: "15px",
        width: "100%",
        border: "none",
        backgroundColor: "#F7F7F7",
        outline: "none",
    },
    textarea: {
        height: "100px",
        padding: "15px",
        width: "100%",
        border: "none",
        backgroundColor: "#F7F7F7",
        outline: "none",
        resize: "none",
    },
    button: {
        fontWeight: "600",
        width: "100%",
        padding: "15px",
        border: "none",
        color: "#ffffff",
        cursor: "pointer",
    },
}
